import { AuthFetch } from '../frontend-auth/auth-wrapper';

import { Component, Doc, MText, renderDoc, styleElements, cssPropertyName } from "../template-render-html/component";
import { activateOverlay, showSuccess } from '../main';

export class TemplatedDocEditor {
    private authFetch: AuthFetch;
    private groupId?: string;
    private docId?: string;
    private doc?: Doc;
    private renderedDiv?: HTMLElement;
    public embedPdf: HTMLEmbedElement;
    private templateDiv: HTMLDivElement;
    private bar: boolean;
    private nextTableID = 1;
    private currentTableID = 0;

    constructor(authFetch: AuthFetch, embedPdf: HTMLEmbedElement, templateDiv: HTMLDivElement) {
        this.authFetch = authFetch;
        this.embedPdf = embedPdf;
        this.templateDiv = templateDiv;
        this.showToolbar = this.showToolbar.bind(this);
        this.removeToolbar = this.removeToolbar.bind(this);
        this.changeElement = this.changeElement.bind(this);
        this.hoverButtons = this.hoverButtons.bind(this);
        this.removeHoverButtons = this.removeHoverButtons.bind(this);
        this.tableButtons = this.tableButtons.bind(this);
        this.removeTableButtons = this.removeTableButtons.bind(this);
        this.keyHandler = this.keyHandler.bind(this);
        this.bar = false;
        this.templateDiv.addEventListener("mouseleave", () => {
            this.removeHoverButtons();
            this.removeTableButtons();
        });
        this.contextMenu = this.contextMenu.bind(this);

        this.addListeners = this.addListeners.bind(this);
        this.HTMLtoComponent = this.HTMLtoComponent.bind(this);
        this.parseComponent = this.parseComponent.bind(this);
        this.parse = this.parse.bind(this);
        this.listMerge = this.listMerge.bind(this);

        document.addEventListener("click", () => {
            templateDiv.querySelectorAll("menu").forEach(menu => menu.remove());
        })
    }

    fetchAndEdit(groupId: string, docId: string) {
        this.groupId = groupId;
        this.docId = docId;
        // TODO we should show the user the document info in some way
        console.log("Getting templated", groupId, docId);
        return Promise.all([
            this.authFetch("/get-pdf", {
                method: 'POST',
                body: JSON.stringify({ GroupId: groupId, DocId: docId }),
            }).then(resp => {
                if (!resp.ok) throw new Error('non 200 status');
                return resp.blob()
            }).then(blob => {
                this.embedPdf.src = URL.createObjectURL(blob) + "#navpanes=0";
            }),
            this.authFetch('/get-templated-doc', {
                method: 'POST',
                body: groupId + "/" + docId,
            }).then(resp => {
                if (!resp.ok) throw new Error('non 200 status');
                return resp.json() as Promise<{ doc: Doc }>;
            }).then(({ doc }) => this.edit(doc))
        ]);
    }

    edit(doc: Doc) {
        this.doc = doc;
        this.renderedDiv = renderDoc(doc);
        this.renderedDiv.querySelectorAll("h1, h2, h3, ul, p, address, table").forEach(node => {
            this.addListeners(node as HTMLElement);
        })

        this.templateDiv.style.paddingTop = `${doc.page_margin_top}in`;
        this.templateDiv.style.paddingBottom = `${doc.page_margin_bottom}in`;
        this.templateDiv.style.paddingLeft = `${doc.page_margin_left}in`;
        this.templateDiv.style.paddingRight = `${doc.page_margin_right}in`;
        this.templateDiv.style.width = `${doc.page_width}in`;

        this.templateDiv.innerHTML = "";
        this.templateDiv.appendChild(this.renderedDiv);

        this.listMerge();
    }

    addListeners(elem: HTMLElement) {
        if (elem.nodeName === "TABLE") {
            elem.addEventListener("contextmenu", this.contextMenu);
            elem.addEventListener("mouseover", () => this.tableButtons(elem));
        } else {
            plaintext(elem);
            elem.addEventListener("focus", this.showToolbar)
            elem.addEventListener("focusout", this.removeToolbar)
            elem.addEventListener("keydown", this.keyHandler)
            elem.addEventListener("mouseover", () => this.hoverButtons(elem));
        }
    }

    HTMLtoComponent(parent: HTMLElement): Component[] {
        const children = [...parent.childNodes];

        // If every child is a span or text node, we can just use parseComponent.
        if (children.every(child => child.nodeType === Node.TEXT_NODE || child.nodeName === "SPAN")) {
            return [{
                span: this.parseComponent(parent),
            }];
        }

        const components: Component[] = [];
        children.forEach(node => {
            if (node.nodeType === Node.TEXT_NODE) {
                components.push({ span: node.textContent || "" });
                return;
            }

            const elem = node as HTMLElement;

            switch (elem.nodeName) {
                case "H1":
                    components.push({ h1: this.parseComponent(elem) })
                    break;
                case "H2":
                    components.push({ h2: this.parseComponent(elem) })
                    break;
                case "H3":
                    components.push({ h3: this.parseComponent(elem) })
                    break;
                case "P":
                    components.push({ p: this.parseComponent(elem) })
                    break;
                case "ADDRESS":
                    if (elem.classList.contains("address-right")) {
                        components.push({ addressRight: this.parseComponent(elem) })
                    }
                    break;
                case "UL":
                    components.push({
                        ul: ([...elem.children] as HTMLElement[])
                            .filter(child => child.nodeName === "LI")
                            .map(this.HTMLtoComponent),
                    })
                    break;
                case "TABLE":
                    components.push({
                        table: ([...elem.children] as HTMLElement[])
                            .filter(firstChild => firstChild.nodeName === "TR")
                            .map(row => ([...row.children] as HTMLElement[])
                                .filter(item => item.nodeName === "TD")
                                .map(this.HTMLtoComponent)
                            ),
                    })
                    break;
                case "DIV":
                    if (elem.classList.contains("simple-row")) {
                        components.push({ row: (this.HTMLtoComponent(elem)) });
                        break;
                    }
                // fallthrough here, since we don't know what this div is
                default:
                    console.error("unknown node name: " + elem.nodeName);
                    break;
            }
        })
        return components;
    }

    parseComponent(component: HTMLElement): MText {
        const result: MText[] = [];
        component.childNodes.forEach(node => {
            if (node.nodeType === Node.TEXT_NODE)
                result.push(node.textContent || "");
            else {
                const elem = node as HTMLElement;
                let style: Record<string, string> = {};

                const type = elem.nodeName;
                switch (type) {
                    case "B":
                        style.fontWeight = "bold";
                        break;
                    case "I":
                        style.fontStyle = "italic";
                        break;
                    case "U":
                        style.textDecoration = "underline";
                        break;
                    case "S":
                        style.textDecoration = "line-through";
                        break;
                    case "SUP":
                        style.verticalAlign = "super";
                        break;
                    case "SUB":
                        style.verticalAlign = "sub";
                        break;
                    case "SPAN":
                        for (const prop of styleElements) {
                            const value = elem.style.getPropertyValue(cssPropertyName(prop));
                            if (value) style[prop] = value;
                        }
                        break;
                    default:
                        const computed = getComputedStyle(elem);
                        for (const prop of styleElements) {
                            const value = computed.getPropertyValue(cssPropertyName(prop));
                            if (value) style[prop] = value;
                        }
                }
                result.push({
                    content: this.parseComponent(elem) || "",
                    style,
                });
            }
        });

        return result.length === 1 ? result[0] : result;
    }

    parse(): Doc {
        if (!this.doc || !this.renderedDiv)
            throw new Error("You cannot save a doc if you haven't opened one.")
        return {
            ...this.doc,
            components: this.HTMLtoComponent(this.renderedDiv),
        }
    }

    save() {
        activateOverlay();
        return this.authFetch('/submit-templated-doc', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                GroupId: this.groupId,
                DocId: this.docId,
                Doc: this.parse(),
            }),
        }).then(resp => {
            if (!resp.ok) throw new Error('non 200 status');
            showSuccess();
        });
    }

    setVisibility(show: boolean) {
        this.templateDiv.style.display = show ? "unset" : "none";
    }

    showToolbar(ev: FocusEvent) {
        const target = ev.target as HTMLElement;
        const bar = document.createElement("div");
        bar.className = "toolbar";
        const outerRect = this.templateDiv.getBoundingClientRect();

        this.removeToolbar();

        bar.style.left = `${15 + outerRect.left}px`;
        bar.style.top = "15px";

        const boldLabel = document.createElement("b");
        boldLabel.innerText = "B";
        bar.appendChild(this.toolbarButton(
            () => {
                target.contentEditable = "true";
                document.execCommand("bold");
                plaintext(target);
            },
            boldLabel,
        ));

        const underlineLabel = document.createElement("u");
        underlineLabel.innerText = "U";
        bar.appendChild(this.toolbarButton(
            () => {
                target.contentEditable = "true";
                document.execCommand("underline");
                plaintext(target);
            },
            underlineLabel,
        ));

        const italicLabel = document.createElement("i");
        italicLabel.innerText = "I";
        bar.appendChild(this.toolbarButton(
            () => {
                target.contentEditable = "true";
                document.execCommand("italic");
                plaintext(target);
            },
            italicLabel,
        ));

        //const strikeLabel = document.createElement("s");
        //strikeLabel.innerText = "S";
        //bar.appendChild(this.toolbarButton(() => {
        //    target.contentEditable = "true";
        //    document.execCommand("strikethrough");
        //    target.contentEditable = "plaintext-only";
        //},
        //    strikeLabel))

        const superLabel = document.createElement("span");
        superLabel.innerText = "x²";
        bar.appendChild(this.toolbarButton(
            () => {
                target.contentEditable = "true";
                document.execCommand("superscript");
                plaintext(target);
            },
            superLabel,
        ));

        //const subLabel = document.createElement("sub");
        //subLabel.innerText = "x";
        //bar.appendChild(this.toolbarButton(() => {
        //    target.contentEditable = "true";
        //    document.execCommand("subscript");
        //    target.contentEditable = "plaintext-only";
        //},
        //    subLabel))

        const tableSize = document.createElement("table");
        tableSize.classList.add("new-table")
        for (let row = 1; row < 6; row++) {
            const newRow = document.createElement("tr");
            tableSize.append(newRow);
            for (let col = 1; col < 6; col++) {
                const button = document.createElement("td");
                button.onclick = () => buildTable(row, col);
                newRow.append(button);
            }

        }

        const buildTable = (rows: number, cols: number) => {
            const table = document.createElement("table");
            this.addListeners(table);
            for (let rowCount = 0; rowCount < rows; rowCount++) {
                const row = document.createElement("tr");
                table.appendChild(row);
                for (let colCount = 0; colCount < cols; colCount++) {
                    const cell = document.createElement("td");
                    const para = document.createElement("p");
                    this.addListeners(para);
                    cell.appendChild(para);
                    row.appendChild(cell);
                }
            }
            let node = document.getSelection()?.anchorNode;
            while (!["H1", "H2", "H3", "UL", "P", "ADDRESS"].includes(node?.nodeName!)) {
                node = node?.parentElement;
                if (!node) throw new Error("Failed to find a suitable node.")
            }
            (node as HTMLElement).after(table);
        }

        // CSS does some magic here to make the table popup appear on hover
        bar.appendChild(this.toolbarButton(
            () => { },
            "table",
            undefined,
            tableSize,
        ));

        if (target.nodeName === "UL") {
            //bar.appendChild(this.toolbarButton(() => {
            //    let node = document.getSelection()?.anchorNode;
            //    while (node?.nodeName !== "LI")
            //        node = node?.parentElement;
            //    (node as HTMLElement).after(document.createElement("LI"));
            //},
            //    "+"
            //))

            //const remove = document.createElement("span");
            //remove.innerText = "X";
            //remove.style.color = "red";
            //bar.appendChild(this.toolbarButton(() => {
            //    let node = document.getSelection()?.anchorNode;
            //    while (node?.nodeName !== "LI")
            //        node = node?.parentElement;
            //    (node as HTMLElement)?.remove();
            //},
            //    remove
            //))
        } else {
            bar.appendChild(this.toolbarButton(() => this.changeElement("h1"), "h1"))
            bar.appendChild(this.toolbarButton(() => this.changeElement("h2"), "h2"))
            bar.appendChild(this.toolbarButton(() => this.changeElement("h3"), "h3"))
            bar.appendChild(this.toolbarButton(() => this.changeElement("p"), "p"))
        }

        bar.appendChild(this.toolbarButton(
            () => {
                const node = target as HTMLElement;
                if (node.nodeName !== "UL") {
                    node.contentEditable = "false";
                    const parent = node.parentElement;
                    const after = node?.nextSibling;
                    const list = document.createElement("ul");
                    this.addListeners(list);
                    const item = document.createElement("li");
                    list.appendChild(item);
                    item.appendChild(this.changeElement("P"));
                    parent!.insertBefore(list, after);
                    this.listMerge();
                    list.focus();
                } else {
                    const listItems = [...node.children];
                    const startIdx = listItems.findIndex(elem => document.getSelection()?.containsNode(elem, true));
                    const endIdx = listItems.findLastIndex(elem => document.getSelection()?.containsNode(elem, true));
                    const before = document.createElement("ul");
                    before.append(...listItems.slice(0, startIdx));
                    this.addListeners(before);
                    const after = document.createElement("ul");
                    after.append(...listItems.slice(endIdx + 1));
                    this.addListeners(after);
                    if (after.childElementCount) node.after(after);
                    listItems.slice(startIdx, endIdx + 1)
                        // Consider the children of each list item.
                        .flatMap(li => [...li.childNodes])
                        // :D
                        .reverse()
                        .forEach(child => {
                            if (child.nodeType === Node.TEXT_NODE) {
                                const para = document.createElement("p");
                                this.addListeners(para);
                                para.innerHTML = child.textContent!
                                node.after(para);
                                para.focus();
                            } else if (child.nodeType === Node.ELEMENT_NODE) {
                                this.addListeners(child as HTMLElement);
                                node.after(child);
                                (child as HTMLElement).focus();
                            }
                        });
                    if (before.childElementCount) node.after(before);
                    node.remove();
                    this.listMerge();
                }
            },
            "⋮☰",
        ));

        bar.appendChild(this.toolbarButton(
            () => {
                target.remove();
                this.removeHoverButtons();
                this.removeToolbar();
                this.listMerge();
            },
            "🗑️",
        ));

        bar.addEventListener("mousedown", ev => ev.preventDefault());
        bar.addEventListener("mouseup", ev => ev.preventDefault());
        bar.addEventListener("click", ev => ev.preventDefault());

        this.templateDiv.appendChild(bar);
        this.bar = true;
    }

    hoverButtons(target: HTMLElement) {
        this.removeHoverButtons();

        let inTable = false;
        {
            let parent = target.parentElement;
            while (parent && parent.nodeName !== "TABLE")
                parent = parent.parentElement;
            if (parent) inTable = true;
        }

        const padding = 8;

        const rect = target.getBoundingClientRect();
        const outerRect = this.templateDiv.getBoundingClientRect();
        //directional buttons
        const downOuter = document.createElement("div");
        const down = this.toolbarButton(() => {
            let node = target;
            while (
                node.parentElement
                && !node.parentElement.classList.contains("templated-doc")
                && !(node.parentElement.nodeName !== "TABLE")
            ) node = node.parentElement;
            if (!node.parentElement) throw new Error("Failed to find parent.");
            const para = document.createElement("P");
            this.addListeners(para);
            (node as HTMLElement).after(para);
            para.focus();
        }, "+")
        down.classList.add("hover-button");
        if (inTable) down.classList.add("small");
        downOuter.style.position = "absolute";
        downOuter.style.transform = "translateX(-50%)";
        downOuter.style.top = `${rect.bottom - outerRect.top}px`;
        downOuter.style.left = `${rect.left - outerRect.left + rect.width / 2}px`;

        down.style.position = "relative";
        down.style.top = `${padding}px`;

        downOuter.appendChild(down);
        this.templateDiv.appendChild(downOuter);

        const upOuter = document.createElement("div");
        const up = this.toolbarButton(() => {
            let node = target;
            while (
                node.parentElement
                && !node.parentElement?.classList.contains("templated-doc")
                && !(node.parentElement.nodeName !== "TABLE")
            ) node = node.parentElement;
            if (!node.parentElement) throw new Error("Failed to find parent.");
            const para = document.createElement("P");
            this.addListeners(para);
            (node as HTMLElement).before(para);
            para.focus();
        }, "+")
        up.classList.add("hover-button");
        if (inTable) up.classList.add("small");
        upOuter.style.position = "absolute";
        upOuter.style.transform = "translateX(-50%)";
        upOuter.style.top = `${rect.top - outerRect.top - padding}px`;
        upOuter.style.left = `${rect.left - outerRect.left + rect.width / 2}px`;
        up.style.position = "relative";
        upOuter.style.height = `${padding}px`;
        up.style.transform = "translateY(-100%)";

        upOuter.appendChild(up);
        this.templateDiv.appendChild(upOuter);

        let cell: Node | null = target;
        while (cell && cell.nodeName !== "TD")
            cell = cell?.parentElement;
        let row = cell?.parentElement;
        while (row && row.nodeName !== "TR")
            row = row?.parentElement;
        let table = row?.parentElement;
        while (table && table.nodeName !== "TABLE")
            table = table?.parentElement;

        const rightOuter = document.createElement("div");
        const right = this.toolbarButton((inTable ?
            (() => this.addColumn(target, "after")) :
            () => {
                const node = target as HTMLElement;
                let parent: HTMLElement | null = node;
                while (parent && !parent.classList.contains("simple-row"))
                    parent = parent.parentElement;

                const para = document.createElement("P");
                this.addListeners(para);

                if (parent) {
                    node.after(para);
                    para.focus();
                }
                else {
                    parent = node!.parentElement!;
                    const row = document.createElement("div");
                    row.className = "simple-row";
                    const after = node?.nextSibling;
                    node?.remove();
                    row.appendChild(node);
                    row.appendChild(para);
                    parent.appendChild(row);
                    parent.insertBefore(row, after || null);
                    para.focus();
                }
            }), "+");
        right.classList.add("hover-button");
        if (inTable) right.classList.add("small");
        rightOuter.style.position = "absolute";
        rightOuter.style.transform = "translateY(-50%)";
        rightOuter.style.top = `${rect.top - outerRect.top + rect.height / 2}px`;
        rightOuter.style.left = `${rect.right - outerRect.left}px`;

        right.style.position = "relative";
        right.style.left = `${padding}px`;
        rightOuter.appendChild(right);
        this.templateDiv.appendChild(rightOuter);

        const leftOuter = document.createElement("div");
        const left = this.toolbarButton(inTable ?
            (() => this.addColumn(target, "before")) :
            (() => {
                const node = target as HTMLElement;
                let parent: HTMLElement | null = node;
                while (parent && !parent.classList.contains("simple-row"))
                    parent = parent.parentElement;

                const para = document.createElement("P");
                this.addListeners(para);

                if (parent) {
                    node.before(para);
                    para.focus();
                }
                else {
                    parent = node!.parentElement!;
                    const row = document.createElement("div");
                    row.className = "simple-row";
                    const after = node?.nextSibling;
                    node?.remove();
                    row.appendChild(para);
                    row.appendChild(node);
                    parent.appendChild(row);
                    parent.insertBefore(row, after || null);
                    para.focus();
                }
            }), "+");
        left.classList.add("hover-button");
        if (inTable) left.classList.add("small");
        leftOuter.style.position = "absolute";
        leftOuter.style.transform = "translateY(-50%)";
        leftOuter.style.top = `${rect.top - outerRect.top + rect.height / 2}px`;
        leftOuter.style.right = `${outerRect.right - rect.left}px`
        leftOuter.style.width = `${padding}px`;
        left.style.position = "relative";
        left.style.transform = "translateX(-100%)";
        leftOuter.appendChild(left);
        this.templateDiv.appendChild(leftOuter);

        if (inTable) {
            this.checkIntersects();
        }
        else this.removeTableButtons();
    }

    removeHoverButtons() {
        this.templateDiv.querySelectorAll(".hover-button").forEach(button => button.remove());
    }

    checkIntersects() {
        const hoverButtons = document.querySelectorAll(".hover-button");
        document.querySelectorAll(".table-button").forEach(table => {
            table.classList.remove("slide");
            hoverButtons.forEach(hover => {
                // FIXME: maybe use intersects from frontend-common
                if (intersect(table.getBoundingClientRect(), hover.getBoundingClientRect())) {
                    table.classList.add("slide");
                }
            })
        })
    }

    tableButtons(target: HTMLElement) {
        /** Helper function to set css variables so I can make the buttons move */
        function setVar(variable: string, val: string): void {
            const root = document.querySelector(':root') as HTMLElement;
            root.style.setProperty(variable, val);
        }

        let id = target.getAttribute("data-table-id");
        if (id && id === this.currentTableID.toString()) return;

        if (!id) {
            target.setAttribute("data-table-id", this.nextTableID.toString());
            id = this.nextTableID.toString();
            this.nextTableID++;
        }

        this.currentTableID = Number.parseInt(id);

        this.removeTableButtons();

        const padding = 8;

        const rect = target.getBoundingClientRect();
        const outerRect = this.templateDiv.getBoundingClientRect();

        setVar("--upTop", `${rect.top - outerRect.top}px`);
        setVar("--downTop", `${rect.bottom - outerRect.top + padding}px`);
        setVar("--left", `${rect.left - outerRect.left + rect.width / 2}px`);

        const down = this.toolbarButton(() => {
            let node = target;
            while (node.parentElement && !node.parentElement.classList.contains("templated-doc") && !(node.parentElement.nodeName !== "TABLE"))
                node = node.parentElement;
            if (!node.parentElement) throw new Error("Failed to find parent.");
            const para = document.createElement("P");
            this.addListeners(para);
            node.after(para);
            para.focus();
        }, "+")
        down.classList.add("table-button");
        down.id = "table-down"
        this.templateDiv.appendChild(down);

        const up = this.toolbarButton(() => {
            let node = target;
            while (node.parentElement && !node.parentElement?.classList.contains("templated-doc") && !(node.parentElement.nodeName !== "TABLE"))
                node = node.parentElement;
            if (!node.parentElement) throw new Error("Failed to find parent.");
            const para = document.createElement("P");
            this.addListeners(para);
            node.before(para);
            para.focus();
        }, "+")
        up.classList.add("table-button");
        up.id = "table-up";
        this.templateDiv.appendChild(up);

        this.checkIntersects();
    }

    removeTableButtons() {
        this.currentTableID = 0;
        this.templateDiv.querySelectorAll(".table-button").forEach(button => button.remove());
    }

    toolbarButton(onClick: () => void, label?: string | HTMLElement | Node, img?: string, hoverDiv?: HTMLDivElement) {
        const button = document.createElement("button") as HTMLButtonElement;
        button.className = "toolbar-button";
        button.type = "button";
        button.onclick = (ev) => { ev.preventDefault(); onClick() };
        if (typeof label === "string") button.innerText = label;
        else if (label) button.appendChild(label);
        if (img) {
            const image = document.createElement("img") as HTMLImageElement;
            image.src = img;
            button.appendChild(image);
        }
        if (hoverDiv) {
            hoverDiv.classList.add("hover-div");
            button.append(hoverDiv)
        }

        button.addEventListener("mousedown", ev => ev.preventDefault());
        button.addEventListener("mouseup", ev => ev.preventDefault());
        return button;
    }

    removeToolbar() {
        if (this.bar)
            this.templateDiv.querySelector(".toolbar")?.remove();
    }

    keyHandler(ev: KeyboardEvent) {
        if (ev.shiftKey) return;
        const elem = ev.target as HTMLElement;
        let component: HTMLElement | null = elem;
        while (!["H1", "H2", "H3", "UL", "P", "ADDRESS", "TABLE"].includes(elem?.nodeName!)) {
            component = elem?.parentElement;
            if (!component) throw new Error("Failed to find a suitable node.")
        }
        requestAnimationFrame(() => component && this.hoverButtons(component));

        if (ev.key === "Enter") {
            ev.preventDefault();
            const anchorNode = document.getSelection()?.anchorNode;
            if (!anchorNode) return;
            let current : Node | null = anchorNode;
            // this is a somewhat complicated algorithm...
            // first cycle upwards till we find an appropriate parent
            while (current && !["LI", "P", "H1", "H2", "H3"].includes(current.nodeName))
                current = current.parentNode;

            if (!current) throw new Error("Failed to find a suitable node.");
            const heading = !["LI", "P"].includes(current.nodeName);
            // we need a reference to the parent so that we can remove it at the end
            const parent = current as HTMLElement;
            // we'll potentially need to track multiple layers of parents...
            const beforeLayers = [];
            const afterLayers = [];

            // cycle through each layer of children
            while (current !== anchorNode) {
                if (!current) throw new Error("Somehow lost track of current.");
                if (current.nodeType !== Node.ELEMENT_NODE) throw new Error("current is not an element node");
                const children : ChildNode[] = [...current.childNodes];
                // find which of the children has the anchor node and splice around it
                const target = children.findIndex(node => node.contains(anchorNode));
                const beforeNodes = children.slice(0, target);
                const afterNodes = children.slice(target + 1);

                // create new elements of the same type as the parent, each containing children before/after the target
                const beforeElem = current.cloneNode(false) as HTMLElement;
                const afterElem = heading && current === parent ? document.createElement("p") : current.cloneNode(false) as HTMLElement;
                beforeElem.append(...beforeNodes);
                afterElem.append(...afterNodes);

                beforeLayers.push(beforeElem);
                afterLayers.push(afterElem);

                current = children[target];
            }

            // by this point current will be the original anchorNode
            const offset = document.getSelection()!.anchorOffset;
            const [beforeText, afterText] = [current.textContent!.slice(0, offset), current.textContent!.slice(offset)];

            let beforeElem: Node, afterElem : Node;
            if (current.nodeType === Node.ELEMENT_NODE) {
                beforeElem = current.cloneNode(false) as HTMLElement;
                afterElem = heading && current === parent ? document.createElement("p") : current.cloneNode(false) as HTMLElement;
                beforeElem.textContent = beforeText;
                afterElem.textContent = afterText;
            } else {
                beforeElem = document.createTextNode(beforeText);
                afterElem = document.createTextNode(afterText);
            }

            // we now need to add each element as a child of it's parent
            const newBefore = beforeLayers.reduceRight((before, current) => {
                current.appendChild(before);
                return current;
            }, beforeElem);

            const newAfter = afterLayers.reduceRight((after, current) => {
                current.prepend(after);
                return current;
            }, afterElem);

            if (["P","H1","H2","H3"].includes(newBefore.nodeName)) {
                this.addListeners(newBefore as HTMLElement);
                this.addListeners(newAfter as HTMLElement);
            }

            parent.after(newAfter);
            parent.after(newBefore);
            this.listMerge();
            parent.remove();
            (newAfter as HTMLElement).focus();
            document.getSelection()?.setPosition(newAfter);
        }

        if (ev.key === "Backspace" && document.getSelection()?.anchorOffset === 0) {
            let elem = document.getSelection()?.anchorNode;

            while (elem && !["LI", "P", "H1", "H2", "H3"].includes(elem.nodeName)) {
                elem = elem.parentElement;
            }
            if (!elem) return;
            const item = elem as HTMLElement;

            const content = item.innerHTML;
            let previous = item.previousElementSibling as HTMLElement | null;
            if (previous && previous.nodeName === "UL") previous = previous.querySelector("li:last-of-type");
            if (previous && !["H1","H2","H3","P","LI"].includes(previous.nodeName)) return;
            ev.preventDefault();
            if (previous)  {
                if (item.nodeName === "LI")
                    document.getSelection()?.modify("move", "backward", "character");
                else focusEnd(previous);
                [...item.childNodes].forEach(child => previous.append(child));
                item.remove();
            } else if (item.nodeName === "LI") {
                //create para
                let parent = item.parentElement;
                while (parent?.nodeName !== "UL") {
                    if (!parent) throw new Error("Failed to find parent.");
                    parent = parent.parentElement;
                }
                const para = document.createElement("P");
                para.innerHTML = content;
                this.addListeners(para);
                (parent as HTMLElement).before(para);
                para.focus();
                item.remove();
                // check if the list is now empty, and if so remove the list itself
                if (parent.childNodes.length === 0) parent.remove();
            }
            this.listMerge();
        }

        const inTable = document.getSelection()?.anchorNode?.parentElement?.closest("table") !== null;

        if (ev.key === "ArrowRight") {
            ev.preventDefault();
            const startPos = document.getSelection()?.anchorOffset;
            const startNode = document.getSelection()?.anchorNode;
            document.getSelection()?.modify("move", "forward", "character");
            if (document.getSelection()?.anchorOffset === startPos && document.getSelection()?.anchorNode === startNode) {
                const sibling = elem.nextElementSibling;
                if (sibling) (sibling as HTMLElement).focus();
            }

            if (document.getSelection()?.anchorOffset === startPos && document.getSelection()?.anchorNode === startNode && inTable) {
                let thisElem : HTMLElement | null | undefined = document.getSelection()?.anchorNode?.parentElement?.closest("td");
                if (!thisElem) throw new Error("You're in a table but not a cell.")
                let nextElem = thisElem.nextElementSibling;
                if (!nextElem) {
                    thisElem = thisElem.closest("tr");
                    nextElem = thisElem!.nextElementSibling?.querySelector("td:has(*)") || null;
                }
                if (nextElem) (nextElem.firstElementChild as HTMLElement).focus();
            }
        }

        if (ev.key === "ArrowLeft" && document.getSelection()?.anchorOffset === 0) {
            ev.preventDefault();
            const sibling = elem.previousElementSibling;
            if (sibling) focusEnd(sibling as HTMLElement)
            else if (inTable) {
                let thisElem : HTMLElement | null | undefined = document.getSelection()?.anchorNode?.parentElement?.closest("td");
                if (!thisElem) throw new Error("You're in a table but not a cell.")
                let prevElem = thisElem.previousElementSibling;
                if (!prevElem) {
                    thisElem = thisElem.closest("tr");
                    const arr = [...thisElem!.previousElementSibling?.querySelectorAll("td:has(*)") || []];
                    prevElem =  arr[arr.length - 1];
                }
                if (prevElem) focusEnd(prevElem.lastElementChild as HTMLElement);
            }
        }
    }

    changeElement(newType: string) {
        const selection = document.getSelection();
        let node = selection?.anchorNode;
        while (!["H1", "H2", "H3", "UL", "P", "ADDRESS"].includes(node?.nodeName!)) {
            node = node?.parentElement;
            if (!node) throw new Error("Failed to find a suitable node.")
        }
        const replacement = document.createElement(newType);
        replacement.innerHTML = (node as HTMLElement).innerHTML;
        this.addListeners(replacement);

        (node as HTMLElement).replaceWith(replacement);
        replacement.focus();
        this.listMerge();
        return replacement;
    }

    contextMenu(ev: MouseEvent) {
        this.templateDiv.querySelectorAll("menu").forEach(menu => menu.remove());
        if (ev.button !== 2) return;
        ev.preventDefault();

        let cell: Node | null = ev.target as Node;
        while (cell && cell.nodeName !== "TD")
            cell = cell?.parentElement;
        let row = cell?.parentElement;
        while (row && row.nodeName !== "TR")
            row = row?.parentElement;
        let table = row?.parentElement;
        while (table && table.nodeName !== "TABLE")
            table = table?.parentElement;
        if (!(cell && row && table)) return;

        const colCount = [...row.children].filter(item => item.nodeName === "TD").length;

        const menu = document.createElement("menu");
        menu.style.top = `${ev.clientY}px`;
        menu.style.left = `${ev.clientX}px`;
        this.templateDiv.append(menu);

        const above = document.createElement("button");
        above.setAttribute("type", "button");
        above.innerText = "Insert row above"
        above.onclick = (() => {
            const newRow = document.createElement("tr");
            for (let i = 0; i < colCount; i++) {
                const newCell = document.createElement("td");
                const newPara = document.createElement("p");
                this.addListeners(newPara);
                newCell.append(newPara);
                newRow.append(newCell);
            }
            row.before(newRow);
            menu.remove();
        })
        menu.append(above);

        const below = document.createElement("button");
        below.setAttribute("type", "button");
        below.innerText = "Insert row below"
        below.onclick = (() => {
            const newRow = document.createElement("tr");
            for (let i = 0; i < colCount; i++) {
                const newCell = document.createElement("td");
                const newPara = document.createElement("p");
                this.addListeners(newPara);
                newCell.append(newPara);
                newRow.append(newCell);
            }
            row.after(newRow);
            menu.remove();
        })
        menu.append(below);

        const left = document.createElement("button");
        left.setAttribute("type", "button");
        left.innerText = "Insert column left"
        left.onclick = (() => {
            this.addColumn(cell, "before");
            menu.remove();
        })
        menu.append(left);

        const right = document.createElement("button");
        right.setAttribute("type", "button");
        right.innerText = "Insert column right"
        right.onclick = (() => {
            this.addColumn(cell, "after");
            menu.remove();
        })
        menu.append(right);

        const deleteRow = document.createElement("button");
        deleteRow.setAttribute("type", "button");
        deleteRow.innerText = "Delete row";
        deleteRow.onclick = (() => {
            row.remove();
            menu.remove();
        });
        menu.append(deleteRow);

        const deleteCol = document.createElement("button");
        deleteCol.setAttribute("type", "button");
        deleteCol.innerText = "Delete column";
        deleteCol.onclick = (() => {
            const cells = [...row.children];
            let col = 0;
            while (cells[col] !== cell)
                col++;

            const rows = [...table.children].filter(row => row.nodeName === "TR");
            if ([...rows[0].children].length === 1) return;
            rows.forEach(row => [...row.children][col].remove());
            menu.remove();
        });
        menu.append(deleteCol);
    }

    /**
     * adds a column to a table based on a passed cell
     * @param cell either the cell itself, or any node within that cell
     * @param position must be "before" or "after" to choose new column position
     */
    addColumn(cell: Node, position: string) {
        let start: Node | null = cell;
        while (start && start.nodeName !== "TD")
            start = start?.parentElement;
        let row = start?.parentElement;
        while (row && row.nodeName !== "TR")
            row = row?.parentElement;
        let table = row?.parentElement;
        while (table && table.nodeName !== "TABLE")
            table = table?.parentElement;

        if (!(start && row && table)) throw new Error("Invalid node passed.");

        const cells = [...row.children] as HTMLElement[];
        let col = 0;
        while (cells[col] !== start)
            col++;

        const rows = ([...table.children] as HTMLElement[]).filter(row => row.nodeName === "TR");
        if (position.toLowerCase() === "before") {
            rows.forEach(row => {
                let newCell = document.createElement("td");
                let newPara = document.createElement("p");
                this.addListeners(newPara);
                newCell.append(newPara);
                ([...row.children] as HTMLElement[])[col].before(newCell);
            })
        }
        else if (position.toLowerCase() === "after") {
            rows.forEach(row => {
                let newCell = document.createElement("td");
                let newPara = document.createElement("p");
                this.addListeners(newPara);
                newCell.append(newPara);
                ([...row.children] as HTMLElement[])[col].after(newCell);
            })
        }
        else throw new Error("Not a valid position, must be 'before' or 'after'.")
    }

    listMerge(){
        this.renderedDiv?.querySelectorAll("ul").forEach(list => {
            const next = list.nextElementSibling;
            if (next && next.nodeName === "UL") {
                list.append(...next.children);
                next.remove();
            };
            if (list.childElementCount === 0) list.remove();
        });

        // for any list item which only contains a paragraph, turn the paragraph into a text node
        [...this.renderedDiv?.querySelectorAll("li") || []]
            .filter(li => li.childElementCount === 1 && li.firstElementChild!.nodeName === "P")
            .forEach(li => {
                const para = li.firstElementChild;
                para!.outerHTML = para!.innerHTML;
            });
    }
}

function intersect(first: DOMRect, second: DOMRect): boolean {
    let horizontal = false;
    horizontal ||= first.left <= second.left && second.left <= first.right;
    horizontal ||= second.left <= first.left && first.left <= second.right;
    horizontal ||= first.left <= second.right && second.right <= first.right;
    horizontal ||= second.left <= first.right && first.right <= second.right;
    let vertical = false;
    vertical ||= first.top <= second.top && second.top <= first.bottom;
    vertical ||= second.top <= first.top && first.top <= second.bottom;
    vertical ||= first.top <= second.bottom && second.bottom <= first.bottom;
    vertical ||= second.top <= first.bottom && first.bottom <= second.bottom;
    return horizontal && vertical;
}

function plaintext(elem: HTMLElement) {
    try {
        elem.contentEditable = "plaintext-only";
    } catch (error) {
        elem.contentEditable = "true";
    }
}

function focusEnd(elem: HTMLElement) {
    elem.focus();
    let node = elem as Node;
    while (node.lastChild) node = node.lastChild;    
    document.getSelection()?.setPosition(node, node.textContent?.length);
}